import { stripTags } from "./stripTags";

/**
 * Réduit le contenu à une longueur maximale spécifiée, en ajoutant des caractères spécifiques à la fin si nécessaire.
 * Le contenu est d'abord nettoyé de toutes les balises HTML avant d'être traité.
 *
 * @param {string} content Le contenu à vérifier et éventuellement à raccourcir.
 * @param {number} maxLength La longueur maximale acceptée pour le contenu. Si le contenu dépasse cette longueur, il sera tronqué.
 * @param {string} caracterEnd Le caractère (ou les caractères) à ajouter à la fin du contenu tronqué. Typiquement, il s'agit de "...".
 * @returns {string} Le contenu potentiellement tronqué et nettoyé de balises HTML, avec le caractère de fin ajouté si nécessaire.
 */
export function contentEllipsis(content, maxLength, caracterEnd) {
  if (!content || typeof content !== 'string') {
    return '';
  }

  content = stripTags(content); // Nettoyage des balises HTML
  const wordsOriginArray = content.split(/\s+/); // Découpage du contenu en mots
  const wordsOriginLength = content.length;
  let contentToReturn = [];

  if (wordsOriginLength < maxLength) {
    return wordsOriginArray.join(' '); // Si la longueur est inférieure à maxLength, retourne le contenu original
  } else {
    let currentLength = 0;

    // Parcours des mots pour construire la chaîne tronquée
    for (let i = 0; i < wordsOriginArray.length; i++) {
      const word = wordsOriginArray[i];
      const wordLength = word.length + (i > 0 ? 1 : 0); // Ajoute un espace si ce n'est pas le premier mot
      if (currentLength + wordLength < maxLength) {
        contentToReturn.push(word);
        currentLength += wordLength;
      } else {
        contentToReturn.push(caracterEnd); // Ajoute le caractère de fin et arrête la boucle
        break;
      }
    }
  }
  return contentToReturn.join(' '); // Assemble les mots en une chaîne
};
