import { useEffect } from "react";
//context
import { useGlobalContext } from "../contexts";
//apollo
import client from '../src/utils/apollo/client';
//queries
import { GET_404 } from "../src/queries/pages/get-404";
//routing
import {handleRedirectsAndReturnData} from '../src/utils/slug';
//components
import Layout from "../src/components/layout404"

const error404 = ( { data } ) => {
  const { context, setContext } = useGlobalContext();


  useEffect(() => {
    if (!context.isSet) {
      data.context.options.isSet = true;
      setContext(data.context.options);
    }
  }, []);

	return (
    <Layout
      logos={data?.logos?.options?.logos}
      mainMenu={data?.mainMenu?.edges}
    >
    </Layout>
	);
};

export default error404;

export async function getStaticProps(context) {
	const { data, errors } = await client.query( {
		query: GET_404
	});

	const defaultProps = {
		props: {
			data: data || {}
		},
		revalidate: 1,
	};

	return defaultProps;
}