const { contentEllipsis } = require("./lib/contentEllipsis");

/**
 *  defaultValues
 */
const devCookieOptions = {
  expires: 14,
  path: '/',
  sameSite: 'lax'
}
const removeDevCookieOptions = {
  path: '/',
}

const prodCookieOptions = {
  expires: 365,
  path: '/',
  sameSite: 'lax',
  domain: process.env.NEXT_PUBLIC_NEXTJS_PUBLIC_URL?.replace('https://www', ''),
  secure: true
}
const removeProdCookieOptions = {
  path: '/',
  domain: process.env.NEXT_PUBLIC_NEXTJS_PUBLIC_URL?.replace('https://www', ''),
  secure: true
}

module.exports = {
  devCookieOptions, removeDevCookieOptions,
  prodCookieOptions, removeProdCookieOptions,
  contentEllipsis
};