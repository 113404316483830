import cookie from 'cookie';

export const FALLBACK = 'blocking';

export const isCustomPageUri = ( uri ) => {
	const pagesToExclude = [
		'/',
		'/nos-actualites/',
    '/nos-references/',
    '/nos-produits/',
    '/comparer/'
	];

	return pagesToExclude.includes( uri );
};

export const handleRedirectsAndReturnData = ( defaultProps, data, field ) => {
  if (process.env.ENVIRONNEMENT == "prod" || process.env.ENVIRONNEMENT == "preprod") {
    if ( data == undefined || data?.content == null) {
      return { notFound: true };
    }

    if ( field && data?.content == undefined && data?.content != true ) {
      return { notFound: true };
    }
  }

	return defaultProps;
};

export function getAuthToken( req ) {
  if (!req.headers.cookie) {
    return undefined;
  }

  const cookies = cookie.parse(req.headers.cookie);
  return cookies.token;
}
