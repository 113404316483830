import { isEmpty } from "lodash";
import { v4 } from "uuid";

import Link from "next/link";

import { contentEllipsis } from "../../utils/mods";

const Breadcrumbs = ({breadcrumbs}) => {
  function decodeHtmlEntities(str) {
    return str?.replace(/&amp;/g, '&')
              .replace(/&lt;/g, '<')
              .replace(/&gt;/g, '>')
              .replace(/&quot;/g, '"')
              .replace(/&#39;/g, "'")
              .replace(/&#8217;/g, "’")
              .replace(/&#8220;/g, '"')
              .replace(/&#8221;/g, '"');
  }

  return(
    <div className="breadcrumbs">
      <div className="flex flex-col gap-y-10">
        <Link href="/" >Accueil</Link>
        {!isEmpty(breadcrumbs) && (breadcrumbs.map((item, index) => (
          <Link
            key={v4()}
            href={item.url.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
            className={index+1==breadcrumbs.length ? (`active`) : ``}
          >
            {contentEllipsis(decodeHtmlEntities(item.text), 30, '...')}
          </Link>
        )))}
      </div>
    </div>
  )
}

export default Breadcrumbs