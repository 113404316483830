import { LayoutContextProvider } from "../../../contexts/LayoutContext";

import Head from "next/head"
import { useRef } from "react";

import Header from "../header"
import Breadcrumbs from "../commons/Breadcrumb";
import Heading from "../commons/Heading";
import Footer from "../footer"
import Btn from "../commons/btn";
import CookiesComponent from "../commons/rgpd/CookiesComponent";

const Layout = ({logos, mainMenu, children}) => {
  const bgWMenuOpen = useRef(null);

  return (
    <LayoutContextProvider>
      <Head>
        {logos?.favicon == null ? <link rel="shortcut icon" href="/images/favicon.ico" /> : <link rel="shortcut icon" href={logos?.favicon?.sourceUrl} />}
      </Head>
      <div className={`base-layout antialiased w-full h-full lg:min-h-screen`}>
        <div className={`lg:bg-radial-gradient lg:min-h-screen`}>
        <div ref={bgWMenuOpen} className={`bg-w-menu-open w-full h-full fixed top-0 left-0 z-40 opacity-0 transition-all bg-primary/70`} />
          <Header logos={logos} mainMenu={mainMenu} templateWhite={false} bgWMenuOpen={bgWMenuOpen} />

          <section className={`
            block-common-header block-index-0 relative z-10 h-screen flex items-center justify-center pb-25 lg:pb-40
            lg:h-full
          `}>
            <div className="container hfpt">
              <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
                <div className="col-span-3 absolute top-100 lg:relative lg:top-0 lg:pt-20 xl:col-span-2 xl:pt-30 5xl:pt-40
                ">
                  <Breadcrumbs breadcrumbs={[{text: '404', url: '#'}]} />
                </div>
                <div className="
                  col-span-2 xs:col-span-12 mt-50 text-center text-white
                  lg:col-span-9 lg:col-start-4 lg:text-left lg:mt-0 xl:col-span-10 xl:col-start-3
                ">
                  <Heading tag="h1" text={'Page introuvable'} classes="mb-50 lg:mb-15" animate={false} />
                  <Heading tag="h2" text={'Il semblerait que cette page n\'existe pas.'} classes="intro h5" animate={false} />
                  <Btn type={"404"} classes={'inline-flex px-40 mt-100'}  />
                </div>
              </div>
            </div>
          </section>

          {children}
          <div className="lg:w-full lg:absolute lg:left-0 lg:bottom-0">
            <Footer logos={logos} templateWhite={false} optionsFooter={'noContact'}/>
          </div>
        </div>
        <CookiesComponent />
      </div>
    </LayoutContextProvider>
  )
}

export default Layout